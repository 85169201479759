import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  PageHeader,
  BoxIntroDecor,
  BoxPoster,
  Poster,
} from "../components/styles"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

export const query = graphql`
  query {
    poster: file(relativePath: { eq: "about/poster.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 488, maxHeight: 564) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const About = ({ data }) => {
  return (
    <Layout>
      <SEO title="About" />
      <Grid container alignItems="center" style={{ margin: "0 0 84px 0" }}>
        <Grid item xs={12} md={6}>
          <BoxIntroDecor>
            <PageHeader variant="h1">About</PageHeader>
            <Typography>
              Xyclo Media is an Australian film production company that tells stories from a unique, unseen and diverse perspective.
            </Typography>
            <Typography>
              We partner with creatives from Europe, the US and Asia to develop and produce distinctive content aimed for an international market.
            </Typography>
          </BoxIntroDecor>
        </Grid>
        <Grid item xs={12} md={6}>
          <BoxPoster>
            <Poster fluid={data.poster.childImageSharp.fluid} />
          </BoxPoster>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default About
